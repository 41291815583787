<template>
  <div>
      <div class="">
        <v-tabs
        v-model="activeTabs"
        background-color="transparent"
        fixed-tabs
        dark
        icons-and-text
        slider-color="#2c3e50"
        height="36"
      >
        
        
        <v-tab @change="getTabName(tab.name, index)"  @click="onClickOutside" v-for="(tab, index) in bannersTabs" :key="index" color="#2c3e50">
          <div class="d-flex align-center">
            <span style="color:#2c3e50">
    
              {{ tab.name }}
            </span>
            <v-icon class="ml-1" color="#2c3e50">{{ tab.icon }}</v-icon>
            <Badge value="?" severity="info" class="info-badge ml-1" v-tooltip.top="$t(tab.device_hint)"></Badge>
          </div>
        </v-tab>
        
      </v-tabs>
      <!-- <div class="header-layout">
        <div class="header-layout__select ">
        </div>
        <div class="header-layout__image">{{$t('image')}}</div>
        <div class="header-layout__devices">{{$t('device')}}</div>
        <div class="header-layout__name">{{$t('name')}}</div>
        <div class="header-layout__actions header-layout-actions">
            <div class="header-layout-actions__edit">{{$t('to_edit')}}</div>
            <div class="header-layout-actions__active">{{$t('active')}}</div>
            <div class="header-layout-actions__delete">
            </div>
        </div>
      </div> -->
      </div>
    <div class="my-1 d-flex justify-end">
    <Button @click="closeCreateBanner" v-if="createNewBanner" :disabled="loadingFlag" :loading="loadingFlag" :label="$t('cancel_new_banner')"
                    class="p-button-sm p-button-danger" />
                    <Button  v-else
                    @click="createNewBanner = true" 
                    :disabled="loadingFlag" 
                    :loading="loadingFlag" :label="$t('create_new_banner')"
                                    icon="pi pi-plus" class="p-button-sm p-button-success" 
                                    
                                    />
    </div>
    <v-tabs-items v-model="activeTabs">
      
    <v-tab-item
        
        v-for="(tab, i) in bannersTabs"
        :key="i"
      >
      
      <div class="layout">
        
        <v-row class="layout__row">
          
          <v-col  class="layout__col">
            <h2 v-if="!banners" class="text-uppercase my-1 c-red"> </h2>
            <div class="banners" >
               <div class="banners__item _create" v-if="createNewBanner">
                <div class="banners__select">
                    <v-icon>mdi-menu</v-icon>
                </div>
                <input type="file" ref="uploadImageForNewBanner" accept="image/png, image/jpeg" style="display: none" id="upload-image-for-new-banner"
                @change="uploadImageForNewBanner($event)">
                <div class="banners__image" v-if="!newBanner.image" @click="triggerInputForImageNewBanner">
                  <span>{{ $t('new')  }}</span>
                    <v-icon >mdi-plus</v-icon>
                  </div>
                 <div class="banners__image _have-photo" v-else>
                  <span v-if="newBanner.imageSizes && newBanner.imageSizes && newBanner.imageSizes.width && newBanner.imageSizes.height">
                    {{`${newBanner.imageSizes.width}×${newBanner.imageSizes.height}px`}}
                  </span>
                  <p @click="deleteImage(true)">
                    <v-icon color="var(--white)">mdi-close-thick</v-icon>
                  </p>
                  <img v-if="newBanner.image" :src="newBanner.image" ref="imageNewRef" @load="getNewBannerImageSize()"/>
                </div> 
                <div class="banners__devices">
                  <span>

                    <v-icon color="var(--white)">{{tab.icon}}</v-icon>
                  </span>
              </div>
                <div class="banners__name space-y-1">
                  <InputText style="max-width: 200px;" v-model="newBanner.name" class="p-inputtext-sm"
                :placeholder="$t('name')" />
                <InputText style="max-width: 200px;"  v-model="newBanner.link" class="p-inputtext-sm"
                :placeholder="$t('redirect')" />
                </div>  
                <div class="banners__actions space-x-1">
                  <div class="banners__edit">
                        <Button @click="toSaveNewBanner(newBanner)" 
                        :disabled="loadingBanner" 
                        :loading="loadingBanner"
                        icon="pi pi-save"
                         class="p-button-sm p-button-success px-8" />
                  </div>
                  <InputSwitch :disabled="loadingBanner" class="p-inputswitch-success" 
                  v-model="newBanner.active" />
              <div class="banners__delete">
                <Button icon="pi pi-trash" @click="closeCreateBanner"
          :disabled="loadingBanner"
          class="p-button p-component p-button-icon-only p-button-danger p-button-rounded" />
              </div>
              
              </div>      
          </div>
          <Skeleton width="100%" height="500px" v-if="loadingPage"></Skeleton>
          <drop-list
          v-else
              :items="localBannersOrder"
              class="list"
              @reorder="$event.apply(localBannersOrder)"
            >
            <template v-slot:item="{ item, index }">
              <drag :data="item" handle=".banners__select"  :key="item.id" 
              @click="toEditBanner(item)"
              >
              <ValidationObserver ref="observer" class="banners__item _cursor-pointer" :class="{'_edit' : holdBanner === item}" v-slot="{ invalid, validate }">
                    <div class="banners__select">
                        <v-icon>mdi-menu</v-icon>
                    </div>
                    <input type="file" ref="uploadImageForBanner" accept="image/png, image/jpeg" style="display: none" id="upload-image-for-banner"
                @change="uploadImageForBanner($event, index)">
                    <div class="banners__image" v-if="holdBanner === item" @click="triggerInputForImageBanner">
                      <img v-if="holdBannerFile" :src="holdBanner.image" ref="imageRef" @load="getImageSize(index)"/>
                      <p v-if="holdBannerFile" @click.stop="deleteImage(false)">
                        <v-icon color="var(--white)">mdi-close-thick</v-icon>
                      </p>
                        <v-icon v-else>mdi-plus</v-icon>
                      </div>
                    <div class="banners__image" @click="toEditBanner(item)" v-else >
                      <span v-if="imagesParams[index] && imagesParams[index].width && imagesParams[index].height">{{`${imagesParams[index].width}×${imagesParams[index].height}px`  }}</span> 
                      <img v-if="item.image" :src="`https://minio.takemybet.pro/banners/${item.image}`" ref="imageRef" @load="getImageSize(index)"/>
                    </div>
                    <div class="banners__devices">
                      <span>

                        <v-icon color="var(--white)">{{tab.icon}}</v-icon>
                      </span>
                  </div>
                    <div class="banners__name space-y-1" v-if="holdBanner === item">
                      <ValidationProvider
                  name="name"
                  
                  rules="required|min3"
                  v-slot="{ errors, validate, validated }"
                > 
                  <h4 class="c-text white-space text-left">
                    {{ $t('name') }}<span class="c-red">*</span>:
                  </h4>
                  <div class="d-flex flex-column">
                      <InputText style="max-width: 200px;" v-model="item.name" 
                      :placeholder="$t('name')" 
                      class="p-inputtext-sm"
                      :maxlength="12"
                      :class="{ 'p-invalid' : errors.length > 0 }"
                       />
                    <span v-if="errors.length" class="p-error mt-1 font-weight-bold">{{ errors[0] }}</span>
                  </div>
              </ValidationProvider>
                      <ValidationProvider
                  name="redirect"
                  rules="required"
                  v-slot="{ errors, validate, validated }"
                > 
                  <h4 class="c-text white-space text-left">
                    {{ $t('redirect') }}<span class="c-red">*</span>:
                  </h4>
                  <div class="d-flex flex-column">
                      <InputText style="max-width: 200px;" v-model="item.link" 
                      :placeholder="$t('redirect')" 
                      class="p-inputtext-sm"
                      :maxlength="12"
                      :class="{ 'p-invalid' : errors.length > 0 }"
                       />
                    <span v-if="errors.length" class="p-error mt-1 font-weight-bold">{{ errors[0] }}</span>
                  </div>
              </ValidationProvider>
                      </div>
                    <div class="banners__name" v-else>
                        <span>
                            {{ item.name }}
                        </span>
                        <p>
                            {{ item.link }}
                        </p>
                    </div>
                    
                <div class="banners__actions space-x-1">
                    <div class="banners__edit">
                          <Button @click="handleSubmit(validate)" 
                          v-if="holdBanner === item" 
                          :disabled="loadingBanner" 
                          :loading="loadingBanner"
                          icon="pi pi-save"
                           class="p-button-sm p-button-success px-8" />
                          <Button
                          @click="toEditBanner(item)" 
                          v-else 
                          :disabled="loadingBanner" 
                          :loading="loadingBanner" 
                          icon="pi pi-pencil"
                          class="p-button-sm p-button-warning px-8" />
                    </div>
                    <InputSwitch :disabled="item.isActive_loading" class="p-inputswitch-success" 
                    @change.stop="toggleBanner(item)"
                    v-model="item.active" />
                <div class="banners__delete">
                  <Button icon="pi pi-trash" @click.stop="toEditBanner(item, true)"
            :disabled="loadingBanner"
            class="p-button p-component p-button-icon-only p-button-danger p-button-rounded" />
                </div>
                
                </div>
              </ValidationObserver>
              </drag>
            </template>
            </drop-list>
            
          </div>
          </v-col>
        </v-row>
        
        
      </div>
      </v-tab-item>
    </v-tabs-items>
    </div>
  </template>
  
  <script>
  import { Drag, DropList } from "vue-easy-dnd";
  import { mapGetters } from "vuex";
  export default {
    name: "MainSliderBanners",
    components:{
      Drag,
      DropList,
    },
    data() {
      return {
        activeTabs: 0,
        bannersTabs: [
          {
            icon: 'mdi-monitor',
            name: 'PC',
            device_hint: 'pc_resolution'
          },
          {
            icon: 'mdi-cellphone',
            name: 'Tablet',
            device_hint: 'tablet_resolution'
          },
          {
            icon: 'mdi-tablet',
            name: 'Mobile',
            device_hint: 'mobile_resolution'
          },
          ],
          activeDevice: 'PC',
        // banners: [
        //     {
        //         images: { PC: '01', Mobile: '02', Tablet: '03' },
        //         name: 'Banner1.jpg',
        //         link: '/deposit',
        //          activeDevice: ['PC', 'Tablet', 'Mobile'],
        //         active: true,
        //     },
        //      {
        //          images: { PC: '02', Mobile: '03', Tablet: '01' },
        //          name: 'Banner2.jpg',
        //          link: '/deposit',
        //          activeDevice: ['PC', 'Tablet', 'Mobile'],
        //          active: true,
        //      },
        //      {
        //          images: { PC: '03', Mobile: '01', Tablet: '02' },
        //          name: 'Banner3.jpg',
        //          link: '/deposit',
        //          activeDevice: ['PC', 'Tablet', 'Mobile'],
        //          active: true,
        //      },
        // ],
        devices:[
          {
            item: 'PC',
            icon: 'mdi-monitor',

          },
          {
            item: 'Tablet',
            icon: 'mdi-tablet',
          },
          {
            item: 'Mobile',
            icon: 'mdi-cellphone',
          }
        ],
        localBannersOrder:[],
        loadingFlag: true,
        loadingBanner: false,
        loadingPage: false,
        createNewBanner: false,
        newBanner: {
          image: '',
          file: '',
          imageSizes: '',
          name: '',
          link: '',
          activeDevice: 'PC',
          active: true,
        },
        allBanners: false,
        holdBanner: '',
        holdBannerFile: '',
        imagesParams: {
        },
        sizeExceed: '',
      }
    },
    beforeRouteEnter(to, from, next){
    next(vm => {
      vm.$store.commit('setPageName', 'banners')
    });
    },
    async mounted() {
      await this.toGetAllBanners();
      
      this.loadingFlag = false;
    },
    computed:{
      ...mapGetters({
        banners: 'banners/getMainSliderBanners',
      }),
      filteredBanners() {
      return this.bannersTabs.map(tab => {
        return this.banners.filter(banner => banner.device_type.includes(tab.name));
      });
      
      
    },
    },
    methods: {
      handleSubmit(validate) {
        validate().then(valid => {
          if (valid) {
            this.toSaveBanner();
          }
        });
      },
      async toGetAllBanners(){
        this.loadingPage = true;
        await this.$store.dispatch('banners/awaitGetBanners', 'main_slider');
        this.localBannersOrder = [...this.filteredBanners[0]];
        this.loadingPage = false;
      },
      getTabName(tab, index){
        this.activeDevice = tab;
        this.localBannersOrder = [...this.filteredBanners[index]];
        // debugger;
        this.closeCreateBanner();
      },
    triggerInputForImageNewBanner(){
      document.getElementById('upload-image-for-new-banner').click();
    },
    uploadImageForNewBanner(event) {
      const file = event.target.files[0];
      if (file && file.size <= 15000000) {
        const imageUrl = URL.createObjectURL(file);
        this.newBanner.image = imageUrl;
        this.newBanner.file = file;
      }
    },
    triggerInputForImageBanner(){
      document.getElementById('upload-image-for-banner').click();
    },
    uploadImageForBanner(event, index) {
      const file = event.target.files[0];
      if (file && file.size <= 15000000) {
        // Обновляем свойство images для активного устройства
        const imageUrl = URL.createObjectURL(file);
        this.holdBanner.image = imageUrl;
        this.holdBannerFile = file;
            //this.$set(this.imagesParams, index, {
            //  width: image.naturalWidth,
            //  height: image.naturalHeight
            //});
        // this.getSizeNewBanner(index);
      }
    },
    getSizeNewBanner(index) {
      const image = this.$refs.NewImageRef[index];
      if (image) {
        this.$nextTick(() => {
          console.log('image', image.naturalHeight);
          if (image.complete && image.naturalWidth && image.naturalHeight) {
            //this.$set(this.imagesParams, index, {
            //  width: image.naturalWidth,
            //  height: image.naturalHeight
            //});
            //console.log('width', image.naturalWidth);
          }
        });
      }
    },
    deleteImage(isNewBanner) {
      if (isNewBanner) {
          const imageUrl = this.newBanner.image;
          URL.revokeObjectURL(imageUrl);
          this.newBanner.image = null;
          this.newBanner.file = null;
          //this.$refs.uploadImageForNewBanner.value = null;
        } else{
          const imageUrl = this.holdBanner.image;

          URL.revokeObjectURL(imageUrl);

          this.holdBanner.image = '';
          this.holdBannerFile = '';
          // this.$delete(this.holdBanner, 'file');
          this.$refs.uploadImageForBanner.value = '';
        }
    },
      selectDevice(banner, device) {
      banner.activeDevice = device;
    },
      onClickOutside(){
        if (this.holdBanner !== '') {
          
          this.holdBanner = '';
        }
        
        
      },
      closeCreateBanner(){
        if (this.createNewBanner) {
          this.createNewBanner = false;
          this.newBanner = {
            images: { PC: '', Mobile: '', Tablet: '' },
            name: '',
            activeDevice: 'PC',
            link: '',
            file: '',
            active: false,
          };
        }
      },
      async toSaveNewBanner(newBanner){
        this.loadingBanner = true;
        const { imageSizes, ...bannerData } = newBanner;

        const modifiedBannerData = {
          ...bannerData,
          device_type: this.activeDevice,
        };
        const banner = new FormData();

        banner.append("name", modifiedBannerData.name);
        banner.append("banner_type", 'main_slider');
        banner.append("language_code", 'en');
        banner.append("link", modifiedBannerData.link);
        banner.append("active", modifiedBannerData.active);
        banner.append("device_type", modifiedBannerData.device_type);

        banner.append("image", modifiedBannerData.file);
        const res = await this.$store.dispatch('banners/createBanner', { banner });
        if(res){
        this.$toast.add({ severity: 'success', summary: this.$t('banner_success_created'), life: 4000 })
        this.closeCreateBanner();
        } else{
        this.$toast.add({ severity: 'error', summary: this.$t('just_error'), life: 4000 })
        }
        this.loadingBanner = false;
        this.toGetAllBanners();
      },
      async toSaveBanner(){
        this.loadingBanner = true;
        const bannerData = new FormData();
        const id = this.holdBanner.id;
        bannerData.append("banner_type", 'main_slider');
        bannerData.append("language_code", 'en');
        bannerData.append("name", this.holdBanner.name);
        bannerData.append("link", this.holdBanner.link);
        bannerData.append("active", this.holdBanner.active);
        bannerData.append("device_type", this.holdBanner.device_type);

        bannerData.append("image", this.holdBannerFile);
        const res = await this.$store.dispatch('banners/updateBanner', {data: bannerData, banner_id: id });
        if(res){
        this.$toast.add({ severity: 'success', summary: this.$t('banner_success_updated'), life: 4000 });
        this.holdBanner = '';
      } else{
          this.$toast.add({ severity: 'error', summary: this.$t('just_error'), life: 4000 })
        }
        this.loadingBanner = false;
        this.toGetAllBanners();
      },
      getImageSize(index) {
      const image = this.$refs.imageRef[index];
      if (image) {
        this.$nextTick(() => {
          if (image.complete && image.naturalWidth && image.naturalHeight) {
            this.$set(this.imagesParams, index, {
              width: image.naturalWidth,
              height: image.naturalHeight
            });
          }
        });
      }
    },
    getNewBannerImageSize() {
    const image = this.$refs.imageNewRef[0];
    if (image) {
      this.$nextTick(() => {
        if (image.complete && image.naturalWidth && image.naturalHeight) {
          this.newBanner.imageSizes = {
            width: image.naturalWidth,
            height: image.naturalHeight
          };
        }
      });
    }
  },
    async toggleBanner(banner){
        banner.isActive_loading = true;
        this.loadingBanner = true;
        const bannerData = new FormData();
        const id = banner.id;
        //bannerData.append("name", banner.name);
        //bannerData.append("link", banner.link);
        bannerData.append("active", banner.active);
        //bannerData.append("device_type", banner.device_type);
        //bannerData.append("image", this.holdBannerFile);
        const res = await this.$store.dispatch('banners/toggleBanner', {data: bannerData, banner_id: id });
        if(res){
        this.$toast.add({ severity: 'success', summary: this.$t('banner_success_updated'), life: 4000 })
      } else{
          this.$toast.add({ severity: 'error', summary: this.$t('just_error'), life: 4000 })
      }
        banner.isActive_loading = false;
        await this.toGetAllBanners();
        this.loadingBanner = false;
    },
        async toEditBanner(banner, isDelete = false){
          this.loadingBanner = true;
          if (isDelete) {
            const bannerId = banner.id;
              const res = await this.$store.dispatch('banners/deleteBanner', bannerId);
              if (res) {
              this.$toast.add({ severity: 'success', summary: this.$t('banner_deleted'), life: 4000 })
            } else{
                this.$toast.add({ severity: 'error', summary: this.$t('just_error'), life: 4000 })
              }
              await this.toGetAllBanners();
              this.loadingBanner = false;
          } else{
            this.loadingBanner = false;
            this.closeCreateBanner();
            this.holdBanner = banner;
          }
        },
    },
  
  }
  </script>
  
  <style lang="scss" scoped>
  @mixin gridColumn{
    padding: 6px 5px;
    display: grid;
    grid-template-columns: 30px 150px 95px 1fr 400px;
    column-gap: 8px;
    @media (max-width:750px){
        grid-template-columns: 20px 150px 95px 1fr 250px;
        column-gap: 4px;
    }
    @media (max-width:600px){
        grid-template-columns: 20px 150px 95px 1fr 250px;
        column-gap: 4px;
    }
  }
  .drop-list{
    row-gap: 2px;
    display: flex;
    flex-direction: column;
  }
  .custom-overlay{
    background-color: rgb(255 255 255 / 0.3)!important;
        backdrop-filter: blur(10px);
  }
  .logo-absolute{
    position: absolute;
    width: 60px;
    height: 60px;
    left: 5px;
    top: 5px;
    img{
      max-width: 100%;
      max-height: 100%;
    }
  }
  .close-popup{ 
      display: block;
      position: absolute;
      right: 25px;
      top: 30px;
      width: 25px;
      height: 18px;
      z-index: 4;
      cursor: pointer;
      span,
      &::before,
      &::after {
        content: "";
        transition: all 0.3s ease 0s;
        right: 0;
        position: absolute;
        width: 100%;
        height: 2px;
        background-color: var(--white);
      }
      &::before {
        top: calc(50% - toRem(1));
        transform: rotate(-45deg);
      }
      &::after {
        bottom: calc(50% - toRem(1));
        transform: rotate(45deg);
      }
      span {
        width: 0;
      }
  }
  .header-layout {
    @include gridColumn;
    min-height: 36px;
    align-items: center;
    font-weight: 500;
    //background-color: #fdefef;
    width: 100%;
    z-index: 3;
    position: sticky;
    top: 55px;
    @media (max-width:768px){
      
      overflow-x: auto;
      overflow-y: hidden;
    }
    @media screen and (max-width: 960px), (max-height: 500px) and (orientation: landscape) {
          position: static !important;
      }
    &__select {
        display: flex;
        justify-content:center;
        align-items: center;
    }

    &__image {
    }

    &__name {
        text-align: left;
        
    }

    &__actions {
      justify-content: flex-end;
    }
}
.layout{
  @media (max-width:768px){
      
    overflow-x: auto;
    overflow-y: hidden;
  }
}
.flip-list-move {
  transition: transform 0.5s;
}
.header-layout-actions {
    display: flex;
    align-items: center;
    &__edit {
        flex: 1 1 auto;
    }

    &__active {
    }

    &__delete {
        flex: 0 1 100px;
        @media (max-width:700px){
          flex: 0 1 70px;
        }
    }
}

  .banners {
    overflow: auto;
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;
    row-gap: 2px;
    padding-top: 3px;
    &__select{
      cursor: grab;
        display: flex;
        align-items: center;
        .v-input{
            margin-top: 0!important;
           padding-top: 0!important;
        }
    }
    &__item {
      //cursor: grab;
      
      @include gridColumn;
      align-items: center;
      border: 1px solid var(--surface-500);
      border-radius: 4px;
      background-color: var(--surface-200);
    &._edit{
        background-color: rgb(218 255 229) !important;
        border: 1px solid rgb(1 185 56);
    }
    &._create{
        background-color: rgb(23 123 229 / 13%) !important;
        border: 1px solid rgba(19, 34, 137, 0.5);
    }
    }
    &__image {
      height: 100px;
      width: 150px;
      flex: 0 0 150px;
      position: relative;
      ._edit &, ._create &{
        border-radius: 16px;
        border: 2px dashed #dfe4e8;
        background-color: #f2f6f9;
        display: flex;
        cursor: pointer;
        justify-content:center;
        align-items: center;
      }
      &._have-photo{
        //display: block;
      }
      img{
        border-radius: 16px;
        width: 100%;
        height: 100%;
        //object-fit: contain;
      }
      span, p{
        display: flex;
        justify-content:center;
        align-items: center;
        background-color: var(--red);
        color: var(--white);
        width: auto;
        height: 20px;
        padding: 0 3px;
        position: absolute;
        
        top: -3px;
      }
      span{
        right: -5px;
        border-radius: 4px;
      }
      p{
        border-radius: 50%;
        flex: 0 0 20px;
        width: 20px;
        padding: 0px;
        left: -5px;
        i{
          font-size: 14px;
        }
      }
    }
    &__devices{
      display: flex;
      justify-content:center;
      flex-direction: column;
      align-items: center;
      flex-wrap: wrap;
      span{
        //cursor: pointer;
        margin-bottom: 3px;
        display: flex;
        justify-content:center;
        align-items: center;
        width: 30px;
        height: 30px;
        flex: 0 0 30px;
        background-color: var(--main-flamingo);
        border-radius: 5px;
        &._active{
          background-color: #22c39f;
        }
      }
      
    }
    &__name {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      p{
        font-size: 13px;
      }
    }
    &__actions {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
    
    &__edit {
    }

    &__active {
    }

  }
  </style>
  